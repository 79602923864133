



























import { Vue, Component } from "vue-property-decorator";
import { recruitList } from "@/api";
@Component
export default class jobList extends Vue {
    public list = [];

    public async mounted() {
        let data = await recruitList({
            pageSize: 10,
            pageNum: 1,
        });
        this.list = data;
    }
    public handelJump(item: any) {
        this.$router.push({
            name: "jobDetail",
            query: {
                json: JSON.stringify(item),
            },
        });
    }

    public metaInfo = {
        title: "人才招聘-简单睡眠",
        meta: [
            {
                name: "简单睡眠招聘，总部招聘，门店招聘，导购，店长，区经",
                content: "简单睡眠人才招聘",
            },
        ],
    };
}
